@use '@angular/material' as mat;
@import '../variable';

.modal {

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: $primary;
    color: white;
  }

  &-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 26px;
  }

  &-body{
    background: #eef5f9;
    padding: 20px;
  }

  &-footer {
    background: #eef5f9;
    display: flex;
    justify-content: end;
    gap: 15px;
    padding: 20px;
  }
}
