// global

* {
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: $body-font;
  margin: 0;
  overflow-x: hidden;
  color: $body-text;
  font-weight: 300;
  background: $inverse;
}

html {
  position: relative;
  min-height: 100%;
  background: $white;
}

.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-drawer-container {
  background: $body-color;
}

html .page-wrapper {
  height: calc(100vh - 64px);

  .page-content {
    margin: 0 auto;
    padding: 15px 15px;

    .page-header {
      h3 {
        color: #ffffff !important;
      }
    }

    // max-width: 1300px;
  }
}

// a tag

a:hover,
a:focus,
a {
  text-decoration: none;
}

a.link {
  color: $heading-text;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $theme-color;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

hr {
  border: 0px;
  height: 1px;
  background: $border;
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: $danger;
  background-color: $light-danger;
  border-radius: 0.25rem;
}

.box {
  border-radius: $radius;
  padding: 10px;
}

.list-inline {
  margin-left: 0px;
  padding: 0px;

  li {
    display: inline-block;
    list-style: none;
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.button-row button {
  margin-bottom: 5px;
  margin-right: 5px;
}

html body {
  .position-relative {
    position: relative;
  }

  // list
  .clear {
    clear: both;
  }

  ol li {
    margin: 5px 0;
  }

  // display
  .dl {
    display: inline-block;
  }

  .db {
    display: block;
  }

  //   opacity
  .op-5 {
    opacity: 0.5;
  }

  .op-3 {
    opacity: 0.3;
  }

  .rounded {
    border-radius: $radius;
  }

  //   flex
  .ml-auto {
    margin-left: auto;
  }

  .d-flex {
    display: flex;
  }

  .align-items-center {
    align-items: center;
  }

  .w-70 {
    width: 70%;
  }

  .w-75 {
    width: 75%;
  }

  .w-25 {
    width: 25%;
  }

  .no-shrink {
    flex-shrink: 0;
  }

  .oh {
    overflow: hidden;
  }

  .hide {
    display: none;
  }

  .img-circle {
    border-radius: 100%;
  }

  .radius {
    border-radius: $radius;
  }

  .no-shadow {
    box-shadow: none;
  }

  //   align vertical
  .vt {
    vertical-align: top;
  }

  .vm {
    vertical-align: middle;
  }

  .vb {
    vertical-align: bottom;
  }

  //   horizontal align
  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  //   border
  .b-0 {
    border: none;
  }

  .b-r {
    border-right: 1px solid $border;
  }

  .b-l {
    border-left: 1px solid $border;
  }

  .b-b {
    border-bottom: 1px solid $border;
  }

  .b-t {
    border-top: 1px solid $border;
  }

  .b-all {
    border: 1px solid $border;
  }
}

.mat-card {
  margin: 0 0 30px 0 !important;
}

.divider-box {
  display: block;
  margin: 20px 0;
}

.page-header {
  display: flex;
  justify-content: space-between;
  //margin-bottom: 20px;
  .btn {
    margin: 0 0 0 15px;
  }

  .mat-title {
    margin: 0;
  }

  @media(max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}

.page-action {
  display: flex;
  gap: 15px;
}

div.mat-expansion-panel-body {
  padding: 0 0 16px 15px;

  .mat-list-item a span {
    font-size: 13px;
  }
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  // color: rgb(255 255 255 / 67%) !important;
}

.text-white {
  color: #ffffff !important;
}

.text-red {
  color: #147433 !important;
}

.text-blue {
  color: $secondary !important;
}


.text-gray {
  color: #4D4D4D !important;
}

.covegare-th-bg {
  background-color: $primary !important;
  border-top-left-radius: 15px;
  padding: 14px;
  position: relative;
  left: -40px;
  height: 70px;
  @media (max-width: 599px) {
    padding: 5px;
    height: 55px;
    left: 0;
    img {
      transform: scale(0.7);
    }
  }
  display: flex;
  align-items: center;

  &.tim-ultra {
    border-radius: 0 !important;
  }

  &.tim-internet {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 15px !important;
  }
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  font-size: 12px;
  height: 50px;
}

th.mat-header-cell {
  color: #ffffff;
  background-color: $primary;

  .th-no-padding {
    padding: 0 !important;
  }
}

.mat-title {
  font-weight: bold !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: #ffffff !important;
  border: none !important;

}

.mat-button-base {
  @media(max-width: 600px) {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}

.mat-dialog-actions {
  @media(max-width: 600px) {
    flex-wrap: nowrap !important;
  }
}

.mat-form-field-label {
  color: $primary !important;
}

.img-small {
  width: 120px;
  height: 120px;
  max-width: 200px;
}

.img-big {
  max-width: 300px;
  width: 300px;
}

.svg-big {
  svg {
    transform: scale(1.5);
  }
}

.w-30 {
  width: 30% !important;
  @media(max-width: 700px) {
    width: 100% !important;
  }
}

.btn-search {
  width: 230px;
  @media(max-width: 700px) {
    width: 100%;
  }
}

::ng-deep .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  border: none !important;
}

.mat-dialog-title {
  margin: 0 !important;
}

.ignore-padding {
  padding: 0 !important;
}

.mat-cell {
  color: $body-text !important;
}
