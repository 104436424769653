@use '@angular/material' as mat;

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

//PALETTE
// Paleta primária (com base na cor #022A3A)
$mat-primary: (
  50: #eee5fd,
  100: #d3c0f9,
  200: #b596f5,
  300: #9469f3,
  400: #7945f1,
  500: #591aec,
  600: #4b16e6,
  700: #3209de,
  800: #0000d9, // cor principal
  900: #0000d3,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$mat-secondary: (
  50: #e3f2ff,
  100: #bbdeff,
  200: #90c9ff,
  300: #63b4ff,
  400: #40a4ff,
  500: #2294ff, // cor secundaria
  600: #2486f0,
  700: #2374dd,
  800: #2262ca,
  900: #1f43ab,
  contrast: (
    50: #f9f9f9,
    100: #f2f2f2,
    200: #e9e9e9,
    300: #d9d9d9,
    400: #b5b5b5,
    500: #959595,
    600: #6d6d6d,
    700: #595959,
    800: #3b3b3b,
    900: #1b1b1b,
  )
);


$body-font: 'verdana', sans-serif;
$heading-font: 'verdana', sans-serif;

$primary: mat.get-color-from-palette($mat-primary, 800);
$secondary: mat.get-color-from-palette($mat-secondary, 500);

$body-color: #efefef;
$heading-text: #455a64;
$body-text: #414141;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;


$theme-color: #1e88e5;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;
